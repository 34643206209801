<template>
  <!-- 授信管理 -->
  <div class="hangOutManagementPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="addRow"
        >
          添加
        </el-button>
      </div>
      <Table
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 签署确认弹窗 -->
    <PdfContract
      :sign-or-not="pdfContractConfig.signOrNot"
      :visible.sync="pdfContractConfig.visible"
      :url="pdfContractConfig.url"
      @confirmSign="confirmSign"
    />
    <!-- 申请用印的弹窗 -->
    <Dialog
      ref="dialog"
      :dialog-width="'460px'"
      :edit-form-data="editFormData"
      :add-and-edit-form-item="addAndEditFormItem"
      @getFormData="getFormData"
    />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import PdfContract from '@/components/PdfContract'
import { roundUpToInteger } from '@/unit/index'
import { agentCreditInfoRefusedSign, agentCreditInfoSealApply, agentCreditInfoList, agentCustomerEnableOrDisanle, agentCreditInfoCustomersSign } from '@/http/agentApi/crmManagement.js'
export default {
  components: { Dialog, FormSearch, PdfContract, Table, Pagination },
  data() {
    return {
      editFormData: {},
      pdfContractConfig: {},
      addAndEditFormItem: [{
        label: '审批类型',
        type: 'radio',
        child: [{ value: 1, label: '系统审批' }, { value: 2, label: '钉钉审批' }],
        value: 'oaType'
      }],
      itemData: [
        { label: '额度编号', prop: 'creditNo', width: 200 },
        { label: '公司名称', prop: 'cmpName', width: 240 },
        // { label: '统一社会信用代码', prop: 'cmpUnicode', width: 200 },
        // { label: '联系人', prop: 'contacts', width: 120 },
        // { label: '联系电话', prop: 'contactsPhone', width: 180 },
        { label: '保证金比例(%)', prop: 'depositRate', width: 180 },
        // { label: '所在地址', prop: 'placeProvince,placeCity,placeTown', type: 'dictionaries', dictType: ['PROVINCE', 'CITY', 'AREA'], width: 180 },
        { label: '授信额度(万元)', prop: 'creditTotalAmt', width: 120, type: 'money' },
        { label: '额度到期日', prop: 'validDate', width: 180 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        { label: '状态', prop: 'creditStatus', width: 180, child: this.$store.getters.getDictionaryItem('CREDIT_STATUS') }
      ],
      formItemArr: [
        { type: 'input', label: '额度编号', value: 'creditNo' },
        { type: 'input', label: '公司名称', value: 'cmpName' },
        { type: 'select', label: '状态', value: 'creditStatus', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('CREDIT_STATUS') }
      ],
      loading: false,
      total: 0,
      operationButton: [
        { bType: 'primary', label: '审核', handleEvent: this.skipUrl, child: [{ val: 'status' }] },
        { bType: 'primary', label: '用印申请', val: 'creditStatus', num: 5, handleEvent: this.usingApplication },
        { bType: 'primary', label: '签署', val: 'creditStatus', num: 6, handleEvent: this.signContract },
        { bType: 'primary', label: '拒绝签署', val: 'creditStatus', num: 6, handleEvent: this.refusedSign },
        { bType: 'primary', label: '额度调整', val: 'creditStatus', num: 0, handleEvent: this.skipUrl, withParameters: 'adjustment' },
        { bType: 'primary', label: '详情', handleEvent: this.skipUrl, withParameters: 6 }
      ],
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        custCmpName: ''
      }
    }
  },
  created() {
    if (this.$route.query.fundsAuditFlag) this.formInline.fundsAuditFlag = this.$route.query.fundsAuditFlag
    if (this.$route.query.creditStatus) this.formInline.creditStatus = this.$route.query.creditStatus
  },
  methods: {
    // 拒绝签署
    async refusedSign(row) {
      const confirm = await this.$confirm(`您的操作将拒绝此条授信签署，是否继续？`, '提示', { type: 'error' }).catch(err => err)
      if (confirm !== 'confirm') return
      agentCreditInfoRefusedSign({ id: row.id, creditStatus: row.creditStatus }, () => {
        this.$message.success('成功！')
        this.getdata(true)
      })
    },
    getFormData(value) {
      this.editFormData.oaType = value.oaType
      agentCreditInfoSealApply(this.editFormData, () => {
        this.$message.success('成功')
        this.getdata(true)
        this.$refs.dialog.editFormVisible = false
      })
    },
    // 点击用印申请
    usingApplication(row) {
      this.editFormData.id = row.id
      this.$refs.dialog.editFormVisible = true
      this.$refs.dialog.dialogTitle = '申请审批'
    },
    // 点击去签署按钮
    confirmSign() {
      agentCreditInfoCustomersSign(this.pdfContractConfig.id
        , res => {
          this.$message.success('成功！')
          this.pdfContractConfig.visible = false
          this.pdfContractConfig.data = {}
          window.open(res.data)
        })
    },
    // 点击签署按钮
    signContract(row) {
      this.pdfContractConfig = {
        visible: true,
        url: row.proxyContractUrl,
        id: row.id
      }
    },
    skipUrl(row, index) {
      // 跳转额度调整
      if (index === 'adjustment') return this.$router.push({ path: '/crmManagement/creditAdjust', query: { id: row.id, type: index }})
      // 如果有index的   则是跳转到详情页面的
      if (!index) return this.$router.push({ path: '/crmManagement/creditAudit', query: { id: row.id }})
      this.$router.push({ path: '/crmManagement/creditDetails', query: { id: row.id, index }})
    },
    addRow(row) {
      this.$router.push({ path: '/crmManagement/addCredit', query: { id: row.id }})
    },
    // 更改状态
    modifyState(row) {
      this.$confirm('确定要改变这条数据的状态吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          agentCustomerEnableOrDisanle({ id: row.id, accessStatus: row.accessStatus === 0 ? 1 : 0 }, () => {
            this.$message.success('成功！')
            this.getdata(true)
          })
        })
        .catch()
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      agentCreditInfoList(this.formInline, res => {
        let permissionStr = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfoAgent')).roleNames || []
        userInfo.forEach(item => {
          permissionStr += item.roleCode
        })
        this.listData = [...res.data.pageData]
        // 去角色里面的值  判断有没有权限  如果没有就不显示审核按钮  目前这些权限是写死的
        this.listData.forEach(item => {
          switch (item.creditStatus) {
            // 业务员审核
            case 1:
              if (permissionStr.indexOf('6fbcd5eab28f46c9a2d0e69b705b3c3d') >= 0) {
                item.status = true
              } else {
                item.status = false
              }
              break
              // 风控审核
            case 2:
              if (permissionStr.indexOf('de971eeae433486db49654f8da779715') >= 0) {
                item.status = true
              } else {
                item.status = false
              }
              break
              // 业务负责人
            case 3:
              if (permissionStr.indexOf('cdeab1ead5da46069d2d35d9b086cb37') >= 0) {
                item.status = true
              } else {
                item.status = false
              }
              break
            default:
              item.status = false
              break
          }
          if (item.financialInfo) {
            item.contacts = item.financialInfo.contacts
            item.contactsPhone = item.financialInfo.contactsPhone
            item.cmpUnicode = item.financialInfo.cmpUnicode
          }
          item.creditTotalAmt = roundUpToInteger(Number(item.creditTotalAmt) / 10000)
        })
        // const flag = userInfo.some(item => ['6fbcd5eab28f46c9a2d0e69b705b3c3d', 'cdeab1ead5da46069d2d35d9b086cb37', 'de971eeae433486db49654f8da779715'].includes(item.roleCode))
        this.total = res.data.total
      })
    }
  }
}
</script>
